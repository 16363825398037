import { default as bridgeCWssq3wUDWMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/auth/bridge.vue?macro=true";
import { default as sign_45in1Z6gPl2fcAMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/auth/sign-in.vue?macro=true";
import { default as sign_45upQwP6FPWlnVMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/auth/sign-up.vue?macro=true";
import { default as _91careerId_93sxL2t3CqjLMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/career/detail/[careerId].vue?macro=true";
import { default as indexEyExtwOV2AMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/career/index.vue?macro=true";
import { default as searchvhKHXeztxfMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/career/search.vue?macro=true";
import { default as survey5cKekEzxgIMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/career/survey.vue?macro=true";
import { default as _91collegeId_93OesItmkH9VMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/college/detail/[collegeId].vue?macro=true";
import { default as findOsymrQP4CrMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/college/find.vue?macro=true";
import { default as resultyguBebk66mMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/college/result.vue?macro=true";
import { default as surveymUDspDk7MSMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/college/survey.vue?macro=true";
import { default as viewmoreHrJZn35VNVMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/college/viewmore.vue?macro=true";
import { default as collegesurveyZYZqZlwRvLMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/collegesurvey.vue?macro=true";
import { default as index2H04r1GiEvMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/extracurriculars/index.vue?macro=true";
import { default as extracurricularsejrFhY6Oa1Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/extracurriculars.vue?macro=true";
import { default as indexKx4lAEGX3CMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/index.vue?macro=true";
import { default as index24YayZcEBnQMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/index2.vue?macro=true";
import { default as _91expertId_93eRpZCbPCe2Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/find/detail/[expertId].vue?macro=true";
import { default as indexiXocamb4iYMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/find/index.vue?macro=true";
import { default as indexbFGcf1NjMfMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/find/researchprogram/index.vue?macro=true";
import { default as index4LCbC7wXsTMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/index.vue?macro=true";
import { default as indexS9jsF1NMtAMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/booking/[bookingId]/index.vue?macro=true";
import { default as indexw9CwlznTv3Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/booking/[bookingId]/program/index.vue?macro=true";
import { default as _91programId_93vVEY8f5KrlMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/booking/[bookingId]/program/session/[programId].vue?macro=true";
import { default as _91programId_93TsrC44ZZbnMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/booking/[bookingId]/program/session/payment/[programId].vue?macro=true";
import { default as indexPJx6N9puATMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/booking/index.vue?macro=true";
import { default as indexr0Iq0w9U1eMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/experience/index.vue?macro=true";
import { default as indexLO0pkdO4ONMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/index.vue?macro=true";
import { default as indexYLywRpBo4TMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/programs/[id]/index.vue?macro=true";
import { default as indexWLM7LRMaX2Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/programs/index.vue?macro=true";
import { default as mentoringXRM2zaxzmvMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring.vue?macro=true";
import { default as listaJjRgEHnFQMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/career/list.vue?macro=true";
import { default as listOk4SpIhVbbMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/college/list.vue?macro=true";
import { default as indexaMDHMSN6mIMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/index.vue?macro=true";
import { default as listOG75eg3cGwMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/major/list.vue?macro=true";
import { default as edithxVeX5fwLiMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/profile/edit.vue?macro=true";
import { default as indexrtc9rA9xrFMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/profile/index.vue?macro=true";
import { default as accountsbOFaqtlkdMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/account.vue?macro=true";
import { default as cancel_45subscriptionTK8uV8wZ2QMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/cancel-subscription.vue?macro=true";
import { default as deactivateXKpEO9nOIhMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/deactivate.vue?macro=true";
import { default as deleteJlcAJvkqCJMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/delete.vue?macro=true";
import { default as indexWlLEGPpkbdMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/index.vue?macro=true";
import { default as membershipsGC3epkSC3RMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/memberships.vue?macro=true";
import { default as notificationWRHPlqbls2Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/notification.vue?macro=true";
import { default as mypondB1CnIKWaMHMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond.vue?macro=true";
import { default as callbackEBveJl4yczMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/open/sign-up/cronofy/callback.vue?macro=true";
import { default as subscriptionqo9TK5vvJBMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/purchase/subscription.vue?macro=true";
import { default as subscriptionNoAuthH7grgbWOEmMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/purchase/subscriptionNoAuth.vue?macro=true";
import { default as viewingpassZ318JTFBzbMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/purchase/viewingpass.vue?macro=true";
import { default as _91tab_935k84wZzOOYMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/[tab].vue?macro=true";
import { default as _91reportId_93r1ADCDC5cNMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/analysis/[reportId].vue?macro=true";
import { default as indexZbTam7UtnyMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/analysis/index.vue?macro=true";
import { default as indexEd8kS4lue7Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/index.vue?macro=true";
import { default as collegedvEliALunGMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/welcome/college.vue?macro=true";
import { default as indexaCcRP5qCbJMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/welcome/index.vue?macro=true";
import { default as majorxayIu9Ajq1Meta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/welcome/major.vue?macro=true";
import { default as roadmap3fDCSifLHZMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap.vue?macro=true";
import { default as surveyUTNbMXTjMtMeta } from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/survey.vue?macro=true";
export default [
  {
    name: bridgeCWssq3wUDWMeta?.name ?? "auth-bridge",
    path: bridgeCWssq3wUDWMeta?.path ?? "/auth/bridge",
    meta: bridgeCWssq3wUDWMeta || {},
    alias: bridgeCWssq3wUDWMeta?.alias || [],
    redirect: bridgeCWssq3wUDWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/auth/bridge.vue").then(m => m.default || m)
  },
  {
    name: sign_45in1Z6gPl2fcAMeta?.name ?? "auth-sign-in",
    path: sign_45in1Z6gPl2fcAMeta?.path ?? "/auth/sign-in",
    meta: sign_45in1Z6gPl2fcAMeta || {},
    alias: sign_45in1Z6gPl2fcAMeta?.alias || [],
    redirect: sign_45in1Z6gPl2fcAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45upQwP6FPWlnVMeta?.name ?? "auth-sign-up",
    path: sign_45upQwP6FPWlnVMeta?.path ?? "/auth/sign-up",
    meta: sign_45upQwP6FPWlnVMeta || {},
    alias: sign_45upQwP6FPWlnVMeta?.alias || [],
    redirect: sign_45upQwP6FPWlnVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: _91careerId_93sxL2t3CqjLMeta?.name ?? "career-detail-careerId",
    path: _91careerId_93sxL2t3CqjLMeta?.path ?? "/career/detail/:careerId()",
    meta: _91careerId_93sxL2t3CqjLMeta || {},
    alias: _91careerId_93sxL2t3CqjLMeta?.alias || [],
    redirect: _91careerId_93sxL2t3CqjLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/career/detail/[careerId].vue").then(m => m.default || m)
  },
  {
    name: indexEyExtwOV2AMeta?.name ?? "career",
    path: indexEyExtwOV2AMeta?.path ?? "/career",
    meta: indexEyExtwOV2AMeta || {},
    alias: indexEyExtwOV2AMeta?.alias || [],
    redirect: indexEyExtwOV2AMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/career/index.vue").then(m => m.default || m)
  },
  {
    name: searchvhKHXeztxfMeta?.name ?? "career-search",
    path: searchvhKHXeztxfMeta?.path ?? "/career/search",
    meta: searchvhKHXeztxfMeta || {},
    alias: searchvhKHXeztxfMeta?.alias || [],
    redirect: searchvhKHXeztxfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/career/search.vue").then(m => m.default || m)
  },
  {
    name: survey5cKekEzxgIMeta?.name ?? "career-survey",
    path: survey5cKekEzxgIMeta?.path ?? "/career/survey",
    meta: survey5cKekEzxgIMeta || {},
    alias: survey5cKekEzxgIMeta?.alias || [],
    redirect: survey5cKekEzxgIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/career/survey.vue").then(m => m.default || m)
  },
  {
    name: _91collegeId_93OesItmkH9VMeta?.name ?? "college-detail-collegeId",
    path: _91collegeId_93OesItmkH9VMeta?.path ?? "/college/detail/:collegeId()",
    meta: _91collegeId_93OesItmkH9VMeta || {},
    alias: _91collegeId_93OesItmkH9VMeta?.alias || [],
    redirect: _91collegeId_93OesItmkH9VMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/college/detail/[collegeId].vue").then(m => m.default || m)
  },
  {
    name: findOsymrQP4CrMeta?.name ?? "college-find",
    path: findOsymrQP4CrMeta?.path ?? "/college/find",
    meta: findOsymrQP4CrMeta || {},
    alias: findOsymrQP4CrMeta?.alias || [],
    redirect: findOsymrQP4CrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/college/find.vue").then(m => m.default || m)
  },
  {
    name: resultyguBebk66mMeta?.name ?? "college-result",
    path: resultyguBebk66mMeta?.path ?? "/college/result",
    meta: resultyguBebk66mMeta || {},
    alias: resultyguBebk66mMeta?.alias || [],
    redirect: resultyguBebk66mMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/college/result.vue").then(m => m.default || m)
  },
  {
    name: surveymUDspDk7MSMeta?.name ?? "college-survey",
    path: surveymUDspDk7MSMeta?.path ?? "/college/survey",
    meta: surveymUDspDk7MSMeta || {},
    alias: surveymUDspDk7MSMeta?.alias || [],
    redirect: surveymUDspDk7MSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/college/survey.vue").then(m => m.default || m)
  },
  {
    name: viewmoreHrJZn35VNVMeta?.name ?? "college-viewmore",
    path: viewmoreHrJZn35VNVMeta?.path ?? "/college/viewmore",
    meta: viewmoreHrJZn35VNVMeta || {},
    alias: viewmoreHrJZn35VNVMeta?.alias || [],
    redirect: viewmoreHrJZn35VNVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/college/viewmore.vue").then(m => m.default || m)
  },
  {
    name: collegesurveyZYZqZlwRvLMeta?.name ?? "collegesurvey",
    path: collegesurveyZYZqZlwRvLMeta?.path ?? "/collegesurvey",
    meta: collegesurveyZYZqZlwRvLMeta || {},
    alias: collegesurveyZYZqZlwRvLMeta?.alias || [],
    redirect: collegesurveyZYZqZlwRvLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/collegesurvey.vue").then(m => m.default || m)
  },
  {
    path: extracurricularsejrFhY6Oa1Meta?.path ?? "/extracurriculars",
    children: [
  {
    name: index2H04r1GiEvMeta?.name ?? "extracurriculars",
    path: index2H04r1GiEvMeta?.path ?? "",
    meta: index2H04r1GiEvMeta || {},
    alias: index2H04r1GiEvMeta?.alias || [],
    redirect: index2H04r1GiEvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/extracurriculars/index.vue").then(m => m.default || m)
  }
],
    name: extracurricularsejrFhY6Oa1Meta?.name ?? undefined,
    meta: extracurricularsejrFhY6Oa1Meta || {},
    alias: extracurricularsejrFhY6Oa1Meta?.alias || [],
    redirect: extracurricularsejrFhY6Oa1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/extracurriculars.vue").then(m => m.default || m)
  },
  {
    name: indexKx4lAEGX3CMeta?.name ?? "index",
    path: indexKx4lAEGX3CMeta?.path ?? "/",
    meta: indexKx4lAEGX3CMeta || {},
    alias: indexKx4lAEGX3CMeta?.alias || [],
    redirect: indexKx4lAEGX3CMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index24YayZcEBnQMeta?.name ?? "index2",
    path: index24YayZcEBnQMeta?.path ?? "/index2",
    meta: index24YayZcEBnQMeta || {},
    alias: index24YayZcEBnQMeta?.alias || [],
    redirect: index24YayZcEBnQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/index2.vue").then(m => m.default || m)
  },
  {
    path: mentoringXRM2zaxzmvMeta?.path ?? "/mentoring",
    children: [
  {
    name: _91expertId_93eRpZCbPCe2Meta?.name ?? "mentoring-find-detail-expertId",
    path: _91expertId_93eRpZCbPCe2Meta?.path ?? "find/detail/:expertId()",
    meta: _91expertId_93eRpZCbPCe2Meta || {},
    alias: _91expertId_93eRpZCbPCe2Meta?.alias || [],
    redirect: _91expertId_93eRpZCbPCe2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/find/detail/[expertId].vue").then(m => m.default || m)
  },
  {
    name: indexiXocamb4iYMeta?.name ?? "mentoring-find",
    path: indexiXocamb4iYMeta?.path ?? "find",
    meta: indexiXocamb4iYMeta || {},
    alias: indexiXocamb4iYMeta?.alias || [],
    redirect: indexiXocamb4iYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/find/index.vue").then(m => m.default || m)
  },
  {
    name: indexbFGcf1NjMfMeta?.name ?? "mentoring-find-researchprogram",
    path: indexbFGcf1NjMfMeta?.path ?? "find/researchprogram",
    meta: indexbFGcf1NjMfMeta || {},
    alias: indexbFGcf1NjMfMeta?.alias || [],
    redirect: indexbFGcf1NjMfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/find/researchprogram/index.vue").then(m => m.default || m)
  },
  {
    name: index4LCbC7wXsTMeta?.name ?? "mentoring",
    path: index4LCbC7wXsTMeta?.path ?? "",
    meta: index4LCbC7wXsTMeta || {},
    alias: index4LCbC7wXsTMeta?.alias || [],
    redirect: index4LCbC7wXsTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/index.vue").then(m => m.default || m)
  },
  {
    name: indexS9jsF1NMtAMeta?.name ?? "mentoring-info-booking-bookingId",
    path: indexS9jsF1NMtAMeta?.path ?? "info/booking/:bookingId()",
    meta: indexS9jsF1NMtAMeta || {},
    alias: indexS9jsF1NMtAMeta?.alias || [],
    redirect: indexS9jsF1NMtAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/booking/[bookingId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexw9CwlznTv3Meta?.name ?? "mentoring-info-booking-bookingId-program",
    path: indexw9CwlznTv3Meta?.path ?? "info/booking/:bookingId()/program",
    meta: indexw9CwlznTv3Meta || {},
    alias: indexw9CwlznTv3Meta?.alias || [],
    redirect: indexw9CwlznTv3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/booking/[bookingId]/program/index.vue").then(m => m.default || m)
  },
  {
    name: _91programId_93vVEY8f5KrlMeta?.name ?? "mentoring-info-booking-bookingId-program-session-programId",
    path: _91programId_93vVEY8f5KrlMeta?.path ?? "info/booking/:bookingId()/program/session/:programId()",
    meta: _91programId_93vVEY8f5KrlMeta || {},
    alias: _91programId_93vVEY8f5KrlMeta?.alias || [],
    redirect: _91programId_93vVEY8f5KrlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/booking/[bookingId]/program/session/[programId].vue").then(m => m.default || m)
  },
  {
    name: _91programId_93TsrC44ZZbnMeta?.name ?? "mentoring-info-booking-bookingId-program-session-payment-programId",
    path: _91programId_93TsrC44ZZbnMeta?.path ?? "info/booking/:bookingId()/program/session/payment/:programId()",
    meta: _91programId_93TsrC44ZZbnMeta || {},
    alias: _91programId_93TsrC44ZZbnMeta?.alias || [],
    redirect: _91programId_93TsrC44ZZbnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/booking/[bookingId]/program/session/payment/[programId].vue").then(m => m.default || m)
  },
  {
    name: indexPJx6N9puATMeta?.name ?? "mentoring-info-booking",
    path: indexPJx6N9puATMeta?.path ?? "info/booking",
    meta: indexPJx6N9puATMeta || {},
    alias: indexPJx6N9puATMeta?.alias || [],
    redirect: indexPJx6N9puATMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/booking/index.vue").then(m => m.default || m)
  },
  {
    name: indexr0Iq0w9U1eMeta?.name ?? "mentoring-info-experience",
    path: indexr0Iq0w9U1eMeta?.path ?? "info/experience",
    meta: indexr0Iq0w9U1eMeta || {},
    alias: indexr0Iq0w9U1eMeta?.alias || [],
    redirect: indexr0Iq0w9U1eMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/experience/index.vue").then(m => m.default || m)
  },
  {
    name: indexLO0pkdO4ONMeta?.name ?? "mentoring-info",
    path: indexLO0pkdO4ONMeta?.path ?? "info",
    meta: indexLO0pkdO4ONMeta || {},
    alias: indexLO0pkdO4ONMeta?.alias || [],
    redirect: indexLO0pkdO4ONMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/index.vue").then(m => m.default || m)
  },
  {
    name: indexYLywRpBo4TMeta?.name ?? "mentoring-info-programs-id",
    path: indexYLywRpBo4TMeta?.path ?? "info/programs/:id()",
    meta: indexYLywRpBo4TMeta || {},
    alias: indexYLywRpBo4TMeta?.alias || [],
    redirect: indexYLywRpBo4TMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/programs/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWLM7LRMaX2Meta?.name ?? "mentoring-info-programs",
    path: indexWLM7LRMaX2Meta?.path ?? "info/programs",
    meta: indexWLM7LRMaX2Meta || {},
    alias: indexWLM7LRMaX2Meta?.alias || [],
    redirect: indexWLM7LRMaX2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring/info/programs/index.vue").then(m => m.default || m)
  }
],
    name: mentoringXRM2zaxzmvMeta?.name ?? undefined,
    meta: mentoringXRM2zaxzmvMeta || {},
    alias: mentoringXRM2zaxzmvMeta?.alias || [],
    redirect: mentoringXRM2zaxzmvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mentoring.vue").then(m => m.default || m)
  },
  {
    path: mypondB1CnIKWaMHMeta?.path ?? "/mypond",
    children: [
  {
    name: listaJjRgEHnFQMeta?.name ?? "mypond-career-list",
    path: listaJjRgEHnFQMeta?.path ?? "career/list",
    meta: listaJjRgEHnFQMeta || {},
    alias: listaJjRgEHnFQMeta?.alias || [],
    redirect: listaJjRgEHnFQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/career/list.vue").then(m => m.default || m)
  },
  {
    name: listOk4SpIhVbbMeta?.name ?? "mypond-college-list",
    path: listOk4SpIhVbbMeta?.path ?? "college/list",
    meta: listOk4SpIhVbbMeta || {},
    alias: listOk4SpIhVbbMeta?.alias || [],
    redirect: listOk4SpIhVbbMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/college/list.vue").then(m => m.default || m)
  },
  {
    name: indexaMDHMSN6mIMeta?.name ?? "mypond",
    path: indexaMDHMSN6mIMeta?.path ?? "",
    meta: indexaMDHMSN6mIMeta || {},
    alias: indexaMDHMSN6mIMeta?.alias || [],
    redirect: indexaMDHMSN6mIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/index.vue").then(m => m.default || m)
  },
  {
    name: listOG75eg3cGwMeta?.name ?? "mypond-major-list",
    path: listOG75eg3cGwMeta?.path ?? "major/list",
    meta: listOG75eg3cGwMeta || {},
    alias: listOG75eg3cGwMeta?.alias || [],
    redirect: listOG75eg3cGwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/major/list.vue").then(m => m.default || m)
  },
  {
    name: edithxVeX5fwLiMeta?.name ?? "mypond-profile-edit",
    path: edithxVeX5fwLiMeta?.path ?? "profile/edit",
    meta: edithxVeX5fwLiMeta || {},
    alias: edithxVeX5fwLiMeta?.alias || [],
    redirect: edithxVeX5fwLiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: indexrtc9rA9xrFMeta?.name ?? "mypond-profile",
    path: indexrtc9rA9xrFMeta?.path ?? "profile",
    meta: indexrtc9rA9xrFMeta || {},
    alias: indexrtc9rA9xrFMeta?.alias || [],
    redirect: indexrtc9rA9xrFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/profile/index.vue").then(m => m.default || m)
  },
  {
    name: accountsbOFaqtlkdMeta?.name ?? "mypond-settings-account",
    path: accountsbOFaqtlkdMeta?.path ?? "settings/account",
    meta: accountsbOFaqtlkdMeta || {},
    alias: accountsbOFaqtlkdMeta?.alias || [],
    redirect: accountsbOFaqtlkdMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/account.vue").then(m => m.default || m)
  },
  {
    name: cancel_45subscriptionTK8uV8wZ2QMeta?.name ?? "mypond-settings-cancel-subscription",
    path: cancel_45subscriptionTK8uV8wZ2QMeta?.path ?? "settings/cancel-subscription",
    meta: cancel_45subscriptionTK8uV8wZ2QMeta || {},
    alias: cancel_45subscriptionTK8uV8wZ2QMeta?.alias || [],
    redirect: cancel_45subscriptionTK8uV8wZ2QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/cancel-subscription.vue").then(m => m.default || m)
  },
  {
    name: deactivateXKpEO9nOIhMeta?.name ?? "mypond-settings-deactivate",
    path: deactivateXKpEO9nOIhMeta?.path ?? "settings/deactivate",
    meta: deactivateXKpEO9nOIhMeta || {},
    alias: deactivateXKpEO9nOIhMeta?.alias || [],
    redirect: deactivateXKpEO9nOIhMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/deactivate.vue").then(m => m.default || m)
  },
  {
    name: deleteJlcAJvkqCJMeta?.name ?? "mypond-settings-delete",
    path: deleteJlcAJvkqCJMeta?.path ?? "settings/delete",
    meta: deleteJlcAJvkqCJMeta || {},
    alias: deleteJlcAJvkqCJMeta?.alias || [],
    redirect: deleteJlcAJvkqCJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/delete.vue").then(m => m.default || m)
  },
  {
    name: indexWlLEGPpkbdMeta?.name ?? "mypond-settings",
    path: indexWlLEGPpkbdMeta?.path ?? "settings",
    meta: indexWlLEGPpkbdMeta || {},
    alias: indexWlLEGPpkbdMeta?.alias || [],
    redirect: indexWlLEGPpkbdMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/index.vue").then(m => m.default || m)
  },
  {
    name: membershipsGC3epkSC3RMeta?.name ?? "mypond-settings-memberships",
    path: membershipsGC3epkSC3RMeta?.path ?? "settings/memberships",
    meta: membershipsGC3epkSC3RMeta || {},
    alias: membershipsGC3epkSC3RMeta?.alias || [],
    redirect: membershipsGC3epkSC3RMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/memberships.vue").then(m => m.default || m)
  },
  {
    name: notificationWRHPlqbls2Meta?.name ?? "mypond-settings-notification",
    path: notificationWRHPlqbls2Meta?.path ?? "settings/notification",
    meta: notificationWRHPlqbls2Meta || {},
    alias: notificationWRHPlqbls2Meta?.alias || [],
    redirect: notificationWRHPlqbls2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond/settings/notification.vue").then(m => m.default || m)
  }
],
    name: mypondB1CnIKWaMHMeta?.name ?? undefined,
    meta: mypondB1CnIKWaMHMeta || {},
    alias: mypondB1CnIKWaMHMeta?.alias || [],
    redirect: mypondB1CnIKWaMHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/mypond.vue").then(m => m.default || m)
  },
  {
    name: callbackEBveJl4yczMeta?.name ?? "open-sign-up-cronofy-callback",
    path: callbackEBveJl4yczMeta?.path ?? "/open/sign-up/cronofy/callback",
    meta: callbackEBveJl4yczMeta || {},
    alias: callbackEBveJl4yczMeta?.alias || [],
    redirect: callbackEBveJl4yczMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/open/sign-up/cronofy/callback.vue").then(m => m.default || m)
  },
  {
    name: subscriptionqo9TK5vvJBMeta?.name ?? "purchase-subscription",
    path: subscriptionqo9TK5vvJBMeta?.path ?? "/purchase/subscription",
    meta: subscriptionqo9TK5vvJBMeta || {},
    alias: subscriptionqo9TK5vvJBMeta?.alias || [],
    redirect: subscriptionqo9TK5vvJBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/purchase/subscription.vue").then(m => m.default || m)
  },
  {
    name: subscriptionNoAuthH7grgbWOEmMeta?.name ?? "purchase-subscriptionNoAuth",
    path: subscriptionNoAuthH7grgbWOEmMeta?.path ?? "/purchase/subscriptionNoAuth",
    meta: subscriptionNoAuthH7grgbWOEmMeta || {},
    alias: subscriptionNoAuthH7grgbWOEmMeta?.alias || [],
    redirect: subscriptionNoAuthH7grgbWOEmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/purchase/subscriptionNoAuth.vue").then(m => m.default || m)
  },
  {
    name: viewingpassZ318JTFBzbMeta?.name ?? "purchase-viewingpass",
    path: viewingpassZ318JTFBzbMeta?.path ?? "/purchase/viewingpass",
    meta: viewingpassZ318JTFBzbMeta || {},
    alias: viewingpassZ318JTFBzbMeta?.alias || [],
    redirect: viewingpassZ318JTFBzbMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/purchase/viewingpass.vue").then(m => m.default || m)
  },
  {
    path: roadmap3fDCSifLHZMeta?.path ?? "/roadmap",
    children: [
  {
    name: _91tab_935k84wZzOOYMeta?.name ?? "roadmap-tab",
    path: _91tab_935k84wZzOOYMeta?.path ?? ":tab()",
    meta: _91tab_935k84wZzOOYMeta || {},
    alias: _91tab_935k84wZzOOYMeta?.alias || [],
    redirect: _91tab_935k84wZzOOYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/[tab].vue").then(m => m.default || m)
  },
  {
    name: _91reportId_93r1ADCDC5cNMeta?.name ?? "roadmap-analysis-reportId",
    path: _91reportId_93r1ADCDC5cNMeta?.path ?? "analysis/:reportId()",
    meta: _91reportId_93r1ADCDC5cNMeta || {},
    alias: _91reportId_93r1ADCDC5cNMeta?.alias || [],
    redirect: _91reportId_93r1ADCDC5cNMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/analysis/[reportId].vue").then(m => m.default || m)
  },
  {
    name: indexZbTam7UtnyMeta?.name ?? "roadmap-analysis",
    path: indexZbTam7UtnyMeta?.path ?? "analysis",
    meta: indexZbTam7UtnyMeta || {},
    alias: indexZbTam7UtnyMeta?.alias || [],
    redirect: indexZbTam7UtnyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/analysis/index.vue").then(m => m.default || m)
  },
  {
    name: indexEd8kS4lue7Meta?.name ?? "roadmap",
    path: indexEd8kS4lue7Meta?.path ?? "",
    meta: indexEd8kS4lue7Meta || {},
    alias: indexEd8kS4lue7Meta?.alias || [],
    redirect: indexEd8kS4lue7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/index.vue").then(m => m.default || m)
  },
  {
    name: collegedvEliALunGMeta?.name ?? "roadmap-welcome-college",
    path: collegedvEliALunGMeta?.path ?? "welcome/college",
    meta: collegedvEliALunGMeta || {},
    alias: collegedvEliALunGMeta?.alias || [],
    redirect: collegedvEliALunGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/welcome/college.vue").then(m => m.default || m)
  },
  {
    name: indexaCcRP5qCbJMeta?.name ?? "roadmap-welcome",
    path: indexaCcRP5qCbJMeta?.path ?? "welcome",
    meta: indexaCcRP5qCbJMeta || {},
    alias: indexaCcRP5qCbJMeta?.alias || [],
    redirect: indexaCcRP5qCbJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/welcome/index.vue").then(m => m.default || m)
  },
  {
    name: majorxayIu9Ajq1Meta?.name ?? "roadmap-welcome-major",
    path: majorxayIu9Ajq1Meta?.path ?? "welcome/major",
    meta: majorxayIu9Ajq1Meta || {},
    alias: majorxayIu9Ajq1Meta?.alias || [],
    redirect: majorxayIu9Ajq1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap/welcome/major.vue").then(m => m.default || m)
  }
],
    name: roadmap3fDCSifLHZMeta?.name ?? undefined,
    meta: roadmap3fDCSifLHZMeta || {},
    alias: roadmap3fDCSifLHZMeta?.alias || [],
    redirect: roadmap3fDCSifLHZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/roadmap.vue").then(m => m.default || m)
  },
  {
    name: surveyUTNbMXTjMtMeta?.name ?? "survey",
    path: surveyUTNbMXTjMtMeta?.path ?? "/survey",
    meta: surveyUTNbMXTjMtMeta || {},
    alias: surveyUTNbMXTjMtMeta?.alias || [],
    redirect: surveyUTNbMXTjMtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/pages/survey.vue").then(m => m.default || m)
  }
]