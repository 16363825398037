import type { Device } from '@nuxtjs/device/dist/runtime/types/index'

export default defineNuxtPlugin( (nuxtApp) => {

  const $device: Device | any = nuxtApp.$device

  const isAndroid = $device.userAgent.includes('Android_Mobile')
  const isIos =  $device.userAgent.includes('iOS_Mobile')

  $device.isAppAndroid = isAndroid
  $device.isAppIos = isIos
  $device.isApp = isAndroid || isIos

})
