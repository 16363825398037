import revive_payload_client_JsyNP6BCVg from "/home/runner/work/letitu-v2-front/letitu-v2-front/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.1_sass@1.63.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_RfqUzhDhXL from "/home/runner/work/letitu-v2-front/letitu-v2-front/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.1_sass@1.63.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_41RLhEwPca from "/home/runner/work/letitu-v2-front/letitu-v2-front/node_modules/.pnpm/@pinia+nuxt@0.4.11_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/.nuxt/components.plugin.mjs";
import unhead_BNGeMvlAgd from "/home/runner/work/letitu-v2-front/letitu-v2-front/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.1_sass@1.63.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_1SKCbo5r2p from "/home/runner/work/letitu-v2-front/letitu-v2-front/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.1_sass@1.63.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_pymtjueDWB from "/home/runner/work/letitu-v2-front/letitu-v2-front/node_modules/.pnpm/nuxt-bugsnag@7.0.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_UwZ5BvksLB from "/home/runner/work/letitu-v2-front/letitu-v2-front/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.6.5_vue@3.3.4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_oCxCdUWtJZ from "/home/runner/work/letitu-v2-front/letitu-v2-front/node_modules/.pnpm/@nuxtjs+device@3.1.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chart_client_PKoyCvGIee from "/home/runner/work/letitu-v2-front/letitu-v2-front/packages/share/plugins/chart.client.ts";
import vue_final_modal_Ewmsv0hPDP from "/home/runner/work/letitu-v2-front/letitu-v2-front/packages/share/plugins/vue-final-modal.ts";
import v_calendar_client_YqzzeIlEdA from "/home/runner/work/letitu-v2-front/letitu-v2-front/packages/share/plugins/v-calendar.client.ts";
import floating_vue_zQLrEqvDc6 from "/home/runner/work/letitu-v2-front/letitu-v2-front/packages/share/plugins/floating-vue.ts";
import firebase_auth_client_M74ZXaRA3Y from "/home/runner/work/letitu-v2-front/letitu-v2-front/packages/share/plugins/firebase-auth.client.ts";
import chunk_reload_client_qylrRlzTo8 from "/home/runner/work/letitu-v2-front/letitu-v2-front/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.1_sass@1.63.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_Q1lHILGgMo from "/home/runner/work/letitu-v2-front/letitu-v2-front/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.1.1_@pinia+nuxt@0.4.11_pinia@2.1.4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import device_detect_KeDs7iX5ew from "/home/runner/work/letitu-v2-front/letitu-v2-front/apps/mobile/plugins/device-detect.ts";
export default [
  revive_payload_client_JsyNP6BCVg,
  router_RfqUzhDhXL,
  plugin_vue3_41RLhEwPca,
  components_plugin_KR1HBZs4kY,
  unhead_BNGeMvlAgd,
  prefetch_client_1SKCbo5r2p,
  plugin_pymtjueDWB,
  plugin_UwZ5BvksLB,
  plugin_oCxCdUWtJZ,
  chart_client_PKoyCvGIee,
  vue_final_modal_Ewmsv0hPDP,
  v_calendar_client_YqzzeIlEdA,
  floating_vue_zQLrEqvDc6,
  firebase_auth_client_M74ZXaRA3Y,
  chunk_reload_client_qylrRlzTo8,
  plugin_Q1lHILGgMo,
  device_detect_KeDs7iX5ew
]