<script setup lang="ts">
import { ModalsContainer } from 'vue-final-modal'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { useOgTitleByPath } from 'share/composables/useUtils'

const api = useApi()
const { renewMember, isLoggedIn, logout } = useMemberSessionStore()

// 초기 진입시 회원정보 세팅하기
if (isLoggedIn) {
  try {
    const { data } = await api.memberData.detail()
    renewMember(data)
  } catch (e) {
    logout()
  }
}

// NOTE: redirect by users' device type
const basePath = api.main.basePath
const isProd = basePath === 'https://api.diveintothepond.com'
const clientProdPcUrl = 'https://diveintothepond.com'
const { isDesktop } = useDevice()

if (isDesktop && isProd) {
  navigateTo(clientProdPcUrl, { external: true })
}

const route = useRoute()

onMounted(() => {
  const title = `The Pond - ${useOgTitleByPath(route.path)}`
  useHead({ title })
})

watch(
  () => route.fullPath,
  async () => {
    const title = `The Pond - ${useOgTitleByPath(route.path)}`
    await useHead({ title })
  }
)
</script>
<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <ModalsContainer />
</template>

<style lang="scss">
@import 'assets/style/common';
</style>
