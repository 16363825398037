export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"},{"hid":"og:title","name":"og:title","content":"The Pond: College Admissions Guide"},{"hid":"og:description","name":"og:description","content":"Discover your dream college with confidence using The Pond's data-driven insights. Your pathway to success starts here!"},{"hid":"og:image","property":"og:image","content":"https://app-cdn.diveintothepond.com/mobile/thumbnail.png"}],"link":[{"rel":"icon","type":"image/x-icon","href":"https://app-cdn.diveintothepond.com/mobile/favicon.ico"}],"style":[],"script":[{"src":"https://maps.googleapis.com/maps/api/js?key=AIzaSyD4Tp66UugYJWam8XdqJ4JssC7sL2szckc&libraries=places&language=en"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appCdnURL = "https://app-cdn.diveintothepond.com/mobile"

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null